<template>
  <div class="menu" v-if="localFilter">
    <div class="menu-main-mobile">
      <h2 @click="toggleExpandedFilter(FILTER_MODEL)" class="menu-toggle menu-toggle-model">{{ __("models") }}</h2>
      <h2 @click="toggleExpandedFilter(FILTER_PRICE)" class="menu-toggle menu-toggle-price">{{ __("price") }}</h2>
      <h2 @click="toggleExpandedFilter(FILTER_LOCATION)" class="menu-toggle menu-toggle-location">
        {{ __("location") }}
      </h2>
    </div>
    <div class="menu-main" ref="menu">
      <div class="menu-filter" :class="{ current: expandedFilter == FILTER_MODEL }">
        <h2 @click="toggleExpandedFilter(FILTER_MODEL)" class="menu-toggle menu-toggle-model">{{ __("models") }}</h2>
        <ul class="menu-series menu-filter-extra" :class="{ hide: expandedFilter == FILTER_MODEL && selectedSeries }">
          <li
            v-for="(serie, index) in series"
            :key="index"
            :class="{ selected: selectedSeries == serie && expandedFilter == FILTER_MODEL }"
          >
            <a @click="selectSeries(serie)">
              {{ serie.name }}
            </a>
          </li>
        </ul>
      </div>
      <div class="menu-filter" :class="{ current: expandedFilter == FILTER_PRICE }">
        <h2 @click="toggleExpandedFilter(FILTER_PRICE)" class="menu-toggle menu-toggle-price">{{ __("price") }}</h2>
        <div class="slider menu-filter-extra">
          <div class="price">
            <div class="min">
              {{ localFilter.price[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} {{ __("currency") }}
            </div>
            <div class="max">
              {{ localFilter.price[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} {{ __("currency") }}
            </div>
          </div>
          <vue-slider
            ref="slider"
            v-model="localFilter.price"
            :dot-size="16"
            :height="2"
            :min="priceRange.min"
            :max="priceRange.max"
            :tooltip="false"
          ></vue-slider>
        </div>
      </div>
      <div class="menu-filter">
        <h2 @click="toggleExpandedFilter(FILTER_LOCATION)" class="menu-toggle menu-toggle-location">
          {{ __("location") }}
        </h2>
      </div>
    </div>
    <div v-if="expandedFilter == FILTER_LOCATION" ref="menupopup" class="menu-popup">
      <div class="map">
        <h1>{{ __("dealers_locator") }}</h1>

        <GmapMap
          :center="{ lat: 61.1244601, lng: 16.5135158 }"
          :zoom="5"
          :mapTypeControl="false"
          :streetViewControl="false"
          :disableDefaultUI="true"
          map-type-id="terrain"
          style="width: 500px; height: 300px"
        >
          <GmapInfoWindow
            :options="map.infoOptions"
            :position="{ lat: map.selectedDealer.lat, lng: map.selectedDealer.lng }"
            :opened="map.selectedDealer != null"
            @closeclick="map.selectedDealer = null"
            v-if="map.selectedDealer != null"
          >
            <p>{{ map.selectedDealer.name }}</p>
          </GmapInfoWindow>
          <GmapMarker
            :key="index"
            v-for="(dealer, index) in dealers"
            :position="{ lat: dealer.lat, lng: dealer.lng }"
            :clickable="true"
            :draggable="false"
            @click="
              center = { lat: dealer.lat, lng: dealer.lng };
              map.selectedDealer = dealer;
            "
            :icon="map.markerIcon"
          />
        </GmapMap>
      </div>
      <div class="dealers-loc">
        <button @click="expandedFilter = null" class="close">{{ __("close") }}</button>
        <h1 class="hidedesktop">{{ __("dealers_locator") }}</h1>
        <p>{{ __("search") }}</p>

        <GmapAutocomplete @place_changed="setPlace" />
        <div class="distance">
          <span class="min">{{ __("distance") }} </span>
          <span class="max"> {{ locationRange }}km </span>
        </div>
        <div class="distance-slider">
          <vue-slider
            ref="slider"
            v-model="locationRange"
            :dot-size="16"
            :height="2"
            :max="1200"
            :tooltip="false"
          ></vue-slider>
        </div>
        <div class="dealers">
          <ul>
            <li v-for="(dealer, index) in dealers" :key="'dealer_' + dealer.buno">
              <input type="checkbox" :id="'dealer_' + index" v-model="localFilter.dealers" :value="dealer" />
              <label :for="'dealer_' + index" :class="{ marked: isMarked(dealer) }">{{ dealer.name }} </label>
            </li>
          </ul>
          <button @click="expandedFilter = null" class="close-btn">{{ __("close") }}</button>
        </div>
      </div>
    </div>
    <div v-else-if="expandedFilter == FILTER_MODEL && selectedSeries" class="menu-popup-model">
      <h1>{{ selectedSeries.name }}</h1>
      <ul>
        <li v-for="(model, index) in selectedSeries.models" :key="model.submodelCode + '_' + index">
          <input type="checkbox" :id="'model_' + index" v-model="localFilter.models" :value="model" />
          <label :for="'model_' + index">
            {{ model.name }}
          </label>
        </li>
      </ul>
      <button @click="(selectedSeries = null), (expandedFilter = null)" class="close-btn">{{ __("close") }}</button>
    </div>
  </div>
</template>

<script>
import vueSlider from "vue-slider-component";
export default {
  components: {
    vueSlider,
  },
  props: ["series", "priceRange", "filter", "dealers"],
  data() {
    return {
      FILTER_MODEL: "model",
      FILTER_PRICE: "price",
      FILTER_LOCATION: "location",
      show: false,
      selectedSeries: null,
      expandedFilter: null,
      locationRange: 1200,
      localFilter: null,
      map: {
        infoContent: "",
        infoWindowPos: {
          lat: 0,
          lng: 0,
        },
        infoWinOpen: false,
        currentMidx: null,
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: 0,
          },
        },
        markerIcon: {
          path: "M 10, 10 m -10, 0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0",
          fillColor: "#0066FF",
          fillOpacity: 0.8,
          scale: 1,
          strokeWeight: 0,
          anchor: { x: 10, y: 10 },
        },
        description: "",
        selectedDealer: null,
        currentPlace: null,
      },
    };
  },
  watch: {
    currentPlace() {
      console.log("Place set, updating selected dealers");
      this.updateSelectedDealers();
    },
    locationRange() {
      if (this.currentPlace) {
        this.updateSelectedDealers();
      }
    },
    localFilter: {
      deep: true,
      handler(oldValue, newValue) {
        this.$emit("update:filter", newValue);
      },
    },
  },
  mounted() {
    this.localFilter = this.filter;
    window.addEventListener("scroll", this.scroll);
    window.addEventListener("scroll", this.menupopupscroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.scroll);
    window.removeEventListener("scroll", this.menupopupscroll);
  },
  methods: {
    scroll() {
      var y = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      let ref = this.$refs.menu;
      let headerSize = parseInt(getComputedStyle(ref).top, 10);
      let offsetAmount = Math.min(y, headerSize);
      ref.style.marginTop = -offsetAmount + "px";
    },
    menupopupscroll() {
      var y = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      let menupopup = this.$refs.menupopup;
      if (menupopup) {
        let headerSize = parseInt(getComputedStyle(menupopup).top, 10);
        let offsetAmount = Math.min(y, headerSize);
        menupopup.style.marginTop = -offsetAmount + "px";
      }
    },
    selectSeries(series) {
      this.expandedFilter = this.FILTER_MODEL;
      this.selectedSeries = series;
    },
    resetFilter() {
      this.localFilter = this.getDefaultFilter();
    },
    getDefaultFilter() {
      return { models: [], price: [], dealers: [] };
    },

    toggleExpandedFilter(filter) {
      if (this.expandedFilter == filter) {
        this.expandedFilter = null;
      } else {
        this.expandedFilter = filter;
      }
    },
    hasFilters() {
      return (this.localFilter &&
        this.localFilter.models.length > 0 ||
        this.localFilter.dealers.length > 0 ||
        (this.localFilter &&
          this.localFilter.price.length == 2 &&
          (this.localFilter.price[0] != this.priceRange.min || this.localFilter.price[1] != this.priceRange.max))
      );
    },
    setPlace(place) {
      this.currentPlace = place;
      this.updateSelectedDealers();
    },
    isMarked(dealer) {
      if (this.localFilter && this.localFilter.dealers && this.localFilter.dealers.length > 0) {
        for (let k in this.localFilter.dealers) {
          if (this.localFilter.dealers[k].buno === dealer.buno) {
            return true;
          }
        }
        return false;
      }
      return true;
    },
    updateSelectedDealers() {
      this.localFilter.dealers.splice(0, this.localFilter.dealers.length);
      for (let k in this.dealers) {
        if (this.isInRadius(this.dealers[k])) {
          this.localFilter.dealers.push(this.dealers[k]);
        }
      }
      if (this.localFilter.dealers.length == this.dealers.length) {
        this.localFilter.dealers.splice(0, this.localFilter.dealers.length);
      }
    },
    isInRadius(dealer) {
      if (this.currentPlace && this.currentPlace.geometry) {
        if (this.currentPlace.geometry.location.lat && this.currentPlace.geometry.location.lng) {
          if (
            typeof this.currentPlace.geometry.location.lat === "function" &&
            typeof this.currentPlace.geometry.location.lat === "function"
          ) {
            let distance = this.getDistanceFromLatLonInKm(
              dealer.lat,
              dealer.lng,
              this.currentPlace.geometry.location.lat(),
              this.currentPlace.geometry.location.lng()
            );
            console.log("Calculated distance", distance);
            return distance <= this.locationRange;
          } else {
            let distance = this.getDistanceFromLatLonInKm(
              dealer.lat,
              dealer.lng,
              this.currentPlace.geometry.location.lat,
              this.currentPlace.geometry.location.lng
            );
            return distance <= this.locationRange;
          }
        }
        return true;
      } else {
        return true;
      }
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      let R = 6371; // Radius of the earth in km
      let dLat = this.deg2rad(lat2 - lat1); // deg2rad below
      let dLon = this.deg2rad(lon2 - lon1);
      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c; // Distance in km
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
  },
};
</script>


<style  scoped lang="scss">
@import "./scss/media/general/menu.scss";
@import "./scss/media/1440/menu.scss";
@import "./scss/media/1280/menu.scss";
@import "./scss/media/1024/menu.scss";
@import "./scss/media/850/menu.scss";
@import "./scss/media/550/menu.scss";
@import "./scss/media/380/menu.scss";
</style>