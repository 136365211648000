<template>
  <div class="vehicles">
    <router-link
      class="vehicle-card"
      :to="{ name: 'Details', params: { id: vehicle.id } }"
      v-for="vehicle in vehicles"
      :key="vehicle.id"
    >
      <div class="base">
        <img class="veh-image" :src="vehicle.beauty_shot" />
        <span class="model info">
          {{ vehicle.model_description }}
        </span>
      </div>
      <div class="info">
        <span class="fuel"> {{ vehicle.wltp_co2_weighted }}</span>
        <span class="co2"> {{ vehicle.wltp_co2_weighted }}</span>
        <span class="price">
          {{ vehicle.retail_price_private.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} {{ vehicle.currency }}
        </span>
        <hr class="hr-line" />
        <span class="location">
          {{ vehicle.dealer ? vehicle.dealer.name : "" }}
        </span>
        <span class="city">
          {{ vehicle.dealer ? vehicle.dealer.city : "" }}
        </span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["vehicles"],
};
</script>

<style  scoped lang="scss">
@import "./scss/media/general/bikes.scss";
@import "./scss/media/1440/bikes.scss";
@import "./scss/media/1280/bikes.scss";
@import "./scss/media/1024/bikes.scss";
@import "./scss/media/850/bikes.scss";
@import "./scss/media/550/bikes.scss";
</style>