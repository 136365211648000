<template>
  <div id="page">
    <header>
      <img class="logo" src="../assets/logo.svg" />
    </header>
    <section id="home-page">
      <Menu :series="series" :dealers="dealers" :price-range="priceRange" :filter.sync="filter" />
      <div id="vehicle-list">
        <div class="applied-filters" v-if="hasFilters()">
          <div class="applied-filters-individual">
            <ul v-if="filter.models.length > 4" class="applied-filters-models">
              <li>
                {{ __("models") }}
                <button class="remove-filter" @click="resetModelFilter()"></button>
              </li>
            </ul>
            <ul v-else class="applied-filters-models">
              <li v-for="(model, index) in filter.models" :key="'model_filter_' + index">
                {{ model.name }}
                <button class="remove-filter" @click="filter.models.splice(index, 1)"></button>
              </li>
            </ul>

            <ul
              class="applied-filters-price"
              v-if="
                filter.price.length == 2 &&
                (this.filter.price[0] != this.priceRange.min || this.filter.price[1] != this.priceRange.max)
              "
            >
              <li>
                {{ filter.price[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} -
                {{ filter.price[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} {{ __("currency") }}
                <button class="remove-filter" @click="resetPriceFilter()"></button>
              </li>
            </ul>
            <ul v-if="filter && filter.dealers.length > 4" class="applied-filters-models">
              <li>
                {{ __("locations") }}
                <button class="remove-filter" @click="resetDealerFilter()"></button>
              </li>
            </ul>
            <ul v-else class="applied-filters-dealers">
              <li v-for="(dealer, index) in filter.dealers" :key="'dealer_filter_' + index">
                {{ dealer.name }}
                <button class="remove-filter" @click="filter.dealers.splice(index, 1)"></button>
              </li>
            </ul>
          </div>
          <div class="reset-filters" @click="resetFilter()">{{ __("reset_filters") }}</div>
        </div>
        <div class="sorting">
          <button @click="toggleSort('price')">
            {{ __("sort_by") }} <span class="sort-target">{{ __("price") }}</span>
          </button>
        </div>
        <Bikes :vehicles="vehicles" v-if="vehicles.length > 0" />
        <div class="vehicle-list-no-matches" v-else-if="loader.firstLoad">{{ __("vehicle_list_no_matches") }}</div>
        <div class="vehicle-list-loader" v-else></div>
        <div class="vehicle-list-other-vehicles" v-if="vehicleDiff.length > 0">
          {{ __("vehicle_list_other_vehicles") }}
        </div>
        <Bikes :vehicles="vehicleDiff" v-if="vehicleDiff.length > 0" />
      </div>
    </section>
  </div>
</template>

<script>
import Bikes from "../components/Bikes.vue";
import Menu from "../components/Menu.vue";
import axios from "axios";
let _ = require("lodash");
export default {
  components: {
    Bikes,
    Menu,
  },
  data() {
    return {
      vehicles: [],
      unfilteredVehicles: [],
      dealers: [],
      filter: this.getDefaultFilter(),
      sort: { price: null },
      offset: 0,
      series: {},
      priceRange: { min: 0, max: 0 },
      models: [],
      loader: {
        index: 0,
        lastIndex: 0,
        data: {},
        startIndex: 0,
        firstLoad: false,
      },
    };
  },
  watch: {
    filter: {
      handler() {
        this.updateData();
      },
      deep: true,
    },
    sort: {
      handler() {
        this.updateData();
      },
      deep: true,
    },
  },
  computed: {
    vehicleDiff() {
      return this.unfilteredVehicles.filter((v1) => {
        return !this.vehicles.some((v2) => {
          return v1.id == v2.id;
        });
      });
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get("/api/filters/series").then((response) => {
        this.series = response.data;
      });
      axios.get("/api/filters/dealers").then((response) => {
        this.dealers = response.data;
      });
      axios.get("/api/filters/price-range").then((response) => {
        console.log(response.data);
        let min = 0;
        let max = 5000000;
        if (response.data && response.data.min) {
          min = response.data.min;
        }
        if (response.data && response.data.max) {
          max = response.data.max;
        }
        this.$set(this.priceRange, "min", min);
        this.$set(this.priceRange, "max", max);
        this.$set(this.filter, "price", [min, max]);
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    updateData: _.debounce(function () {
      this.loadMore(null, true, false);
    }, 200),
    loadMore(event, newData = false, unfiltered = false) {
      let offset = 0;
      if (!newData) {
        offset = this.vehicles.length;
        if (unfiltered) {
          offset = this.unfilteredVehicles.length;
        }
      }
      let request = this.formatRequest(offset);
      if (unfiltered) {
        request = this.formatUnfilteredRequest(offset);
      }
      this.loader.index++;
      const li = this.loader.index;
      if (newData) {
        this.loader.firstLoad = false;
        this.loader.startIndex = li;
      }
      axios.post("/api/vehicles", request).then((response) => {
        response.event = event;
        response.newData = newData;
        response.unfiltered = unfiltered;
        this.loader.firstLoad = true;
        if (li === this.loader.lastIndex + 1) {
          this.loader.lastIndex = li;
          this.loadResponse(response);
          while (this.loader.data[this.loader.lastIndex + 1]) {
            this.loader.lastIndex++;
            this.loadResponse(this.loader.data[this.loader.lastIndex]);
          }
        } else {
          this.loader.data[li] = response;
        }
        if (li >= this.loader.startIndex) {
          if (response.data && response.data.vehicles && response.data.vehicles.length > 0) {
            this.loadMore(event, false, unfiltered);
          } else if (!unfiltered) {
            this.loadMore(event, true, true);
          }
        }
      });
    },
    loadResponse(response) {
      if (response.unfiltered) {
        if (response.newData) {
          this.unfilteredVehicles = [];
        }
        if (this.unfilteredVehicles.length > response.data.offset) {
          this.unfilteredVehicles = this.unfilteredVehicles.slice(0, response.data.offset);
        }
        if (response.data.vehicles != null) {
          this.unfilteredVehicles = [].concat(this.unfilteredVehicles, response.data.vehicles);
        }
      } else {
        if (response.newData) {
          this.vehicles = [];
          this.unfilteredVehicles = [];
        }
        if (this.vehicles.length > response.data.offset) {
          this.vehicles = this.vehicles.slice(0, response.data.offset);
        }
        if (response.data.vehicles != null) {
          this.vehicles = [].concat(this.vehicles, response.data.vehicles);
        }
      }
    },
    toggleSort(field) {
      if (this.sort) {
        if (this.sort[field] == null) {
          this.sort[field] = "desc";
        } else {
          this.sort[field] = null;
        }
        console.log("sorting");
      }
    },
    resetFilter() {
      this.filter = this.getDefaultFilter();
    },
    resetModelFilter() {
      this.$set(this.filter, "models", this.getDefaultFilter().models);
    },
    resetDealerFilter() {
      this.$set(this.filter, "dealers", this.getDefaultFilter().dealers);
    },
    resetPriceFilter() {
      this.$set(this.filter, "price", this.getDefaultFilter().price);
    },
    getDefaultFilter() {
      let min = 0;
      let max = 0;
      if (this.priceRange) {
        min = this.priceRange.min;
        max = this.priceRange.max;
      }
      return { models: [], price: [min, max], dealers: [] };
    },
    hasFilters() {
      return (
        this.filter &&
        (this.filter.models.length > 0 ||
          this.filter.dealers.length > 0 ||
          (this.filter &&
            this.filter.price.length == 2 &&
            (this.filter.price[0] != this.priceRange.min || this.filter.price[1] != this.priceRange.max)))
      );
    },
    formatRequest(offset) {
      let request = {
        filter: {
          models: [],
          dealers: [],
          price_range: this.filter.price,
        },
        sort: this.sort,
        offset: offset,
        per_page: 30,
      };
      if (this.filter) {
        for (const model of this.filter.models) {
          request.filter.models.push(model.submodelCode);
        }
        for (const dealer of this.filter.dealers) {
          request.filter.dealers.push(dealer.buno);
        }
      }
      return request;
    },
    formatUnfilteredRequest(offset) {
      let request = {
        filter: {
          models: [],
          dealers: [],
          price_range: null,
        },
        sort: this.sort,
        offset: offset,
        per_page: 30,
        unfiltered: true,
      };
      return request;
    },
  },
};
</script>


<style scoped lang="scss">
@import "./scss/media/general/home.scss";
@import "./scss/media/1440/home.scss";
@import "./scss/media/1280/home.scss";
@import "./scss/media/1024/home.scss";
@import "./scss/media/850/home.scss";
@import "./scss/media/550/home.scss";
@import "./scss/media/380/home.scss";
</style>


